import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { RequestResetPassword, ResetPasswordService } from '../../../services/reset-password.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { Validations } from '../../shared/validations/validations';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  protected currentPasswordVisible = false;
  protected confirmPasswordVisible = false;
  protected newPasswordVisible = false;
  protected password?: string;
  protected forgot = false;
	private token = '';
  protected email = '';
  private patterPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[._@]).{12,}$/;

  resetPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    currentPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(12), Validators.pattern(this.patterPassword)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(12), Validators.pattern(this.patterPassword)]),
  }, { validators: Validations.matchPasswords });
  constructor(
    private resetPasswordService : ResetPasswordService, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilitiesService,
    private nzModalService: NzModalService) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
			this.forgot = params['forgot'] === 'true';
			this.token = params['token'];
      this.email = params['email'];
		});

    if (this.email) {
      this.resetPasswordForm.get('email').setValue(this.email);
    }
  }  

  submitForm(): void {
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }

    this.resetPassword();
  }

  resetPassword() {
    this.utilsService.updateLoading(true);
    const { email, currentPassword, newPassword, confirmPassword } = this.resetPasswordForm.value;
    const payload: RequestResetPassword = { email, currentPassword, newPassword, confirmPassword, service: 'cashin' };
    this.resetPasswordService.restorePassword(payload, this.token).subscribe((response) => {
      this.utilsService.updateLoading(false);
      this.nzModalService.success({
        nzTitle: 'Success',
        nzContent: response.data.message,
        nzOnOk: () => {
          this.resetPasswordForm.reset();
          this.router.navigate(['/login']);
        },
        nzOnCancel: () => {
          this.resetPasswordForm.reset();
          this.router.navigate(['/login']);
        }
      });
      
    }, (error) => {
      this.utilsService.updateLoading(false);
    });
  }

}
