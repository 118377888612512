import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

export interface RequestResetPassword {
	email: string;
	newPassword: string;
	confirmPassword: string;
	currentPassword: string;
	service: string;
}

interface ResponseResetPassword {
	statusCode: number;
	status: boolean;
	message: string;
	data: Data;
	metadata: unknown;
}

interface Data {
	message: string;
}

@Injectable({
	providedIn: 'root'
})
export class ResetPasswordService {
	private readonly API_ACCESS = environment.api.urlAccess;


	constructor(private httpClient: HttpClient) {}

	forgotPassword(payload: RequestResetPassword) {
		return this.httpClient.post<ResponseResetPassword>(`${this.API_ACCESS}forgot-password`, payload);
	}

	restorePassword(payload: RequestResetPassword, token?: string) {
		return this.httpClient.post<ResponseResetPassword>(`${this.API_ACCESS}reset-password`, payload, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
	}
}
