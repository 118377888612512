import { Component, OnInit } from '@angular/core';
import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Refund } from '../../../interfaces/IRefund';
import { ApiService } from '../../../services/api.service';

@Component({
    selector: 'app-refund',
    templateUrl: './refund.component.html',
    styleUrls: ['./refund.component.scss']
})
export class RefundComponent implements OnInit {
    public destroy$ = new Subject();
    public formRefund = this.fb.group({
        operation: this.fb.group({}),
        customer: this.fb.group({}),
    });
    public selectedIndexTab = 0;
    public idOperation: string = null;
    public operation: any = null;
    public isPIX = false;
    public isRefundCompleted = false;
    public showSuccessData = false;
    protected countryCodeTransaction = ''
    public dataRefund = {Details: {}} as Refund;
    public merchant: any = JSON.parse(this.cookie.get('merchant'));
    public referenceTypes: any[] = [
        {label: 'PublicID', value: 'PublicID'},
        {label: 'MerchantSalesID', value: 'MerchantSalesID'},
    ];
    public types: any[] = [
        {label: 'Total', value: 1},
        {label: 'Parcial', value: 2},
    ];
    public refundTypes: any[] = [
        {label: 'Liquidación', value: 1, disabled: false},
        {label: 'Liquidación - Transfer', value: 2, disabled: false},
    ];
    public documentTypes: any[] = [];
    public documentTypesPER: any[] = [
        {label: 'DNI', value: 'DNI'},
        {label: 'RUC', value: 'RUC'},
        {label: 'PASAPORTE', value: 'PASAPORTE'},
        {label: 'CE', value: 'CE'},
    ];
    public documentTypesBRA: any[] = [
        {label: 'CPF', value: 'CPF'},
        {label: 'CNPJ', value: 'CNPJ'},
    ];
    public documentTypesCOL: any[] = [
        {label: 'CC', value: 'CC'},
        {label: 'CE', value: 'CE'},
        {label: 'NIT', value: 'NIT'},
    ];
    public documentTypesMEX: any[] = [
        {label: 'CURP', value: 'CURP'},
        {label: 'INE', value: 'INE'},
        {label: 'Pasaporte Mexicano', value: 'Pasaporte Mexicano'},
    ];
    public accountTypes: any[] = [
        {label: 'Ahorros', value: 'ahorros'},
        {label: 'Corriente', value: 'corriente'},
    ];
    public keyTypes: any[] = [
        {label: 'Correo', value: 'correo'},
        {label: 'Teléfono', value: 'telefono'},
        {label: 'Alter key', value: 'alter key'},
        {label: 'CPF/CNP', value: 'CPF/CNP'},
    ];

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private cookie: CookieService,
        private api: ApiService,
        private modal: NzModalService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.getId();
        this.changeTypeRefund();
        this.changeType();
        this.changeKeyType();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get formOperation(){ return this.formRefund.get('operation') }
    get formCustomer(){ return this.formRefund.get('customer') }
    
    get isReferenceTypeValid() {
        return this.formOperation.get('referenceType').invalid && this.formOperation.get('referenceType').touched;
    }

    get isReferenceValid() {
        return this.formOperation.get('reference').invalid && this.formOperation.get('reference').touched;
    }

    get isTypeValid() {
        return this.formOperation.get('type').invalid && this.formOperation.get('type').touched;
    }

    get isAmountValid() {
        return this.formOperation.get('amount').invalid && this.formOperation.get('amount').touched;
    }

    get isTypeRefundValid() {
        return this.formOperation.get('typeRefund').invalid && this.formOperation.get('typeRefund').touched;
    }

    get isTransactionTypeValid() {
        return this.formOperation.get('transactionType').invalid && this.formOperation.get('transactionType').touched;
    }

    get isDocumentTypeValid() {
        return this.formCustomer.get('documentType').invalid && this.formCustomer.get('documentType').touched;
    }

    get isDocumentNumberValid() {
        return this.formCustomer.get('documentNumber').invalid && this.formCustomer.get('documentNumber').touched;
    }

    get isEmailValid() {
        return this.formCustomer.get('email').invalid && this.formCustomer.get('email').touched;
    }

    get isPhoneValid() {
        return this.formCustomer.get('phone').invalid && this.formCustomer.get('phone').touched;
    }

    get isBankValid() {
        return this.formCustomer.get('bank').invalid && this.formCustomer.get('bank').touched;
    }

    get isAgencyNumberValid() {
        return this.formCustomer.get('agencyNumber').invalid && this.formCustomer.get('agencyNumber').touched;
    }

    get isAccountTypeValid() {
        return this.formCustomer.get('accountType').invalid && this.formCustomer.get('accountType').touched;
    }

    get isNumberAccountValid() {
        return this.formCustomer.get('numberAccount').invalid && this.formCustomer.get('numberAccount').touched;
    }

    get isKeyTypeValid() {
        return this.formCustomer.get('keyType').invalid && this.formCustomer.get('keyType').touched;
    }

    get isKeyValueValid() {
        return this.formCustomer.get('keyValue').invalid && this.formCustomer.get('keyValue').touched;
    }

    get isFullNameValid() {
        return this.formCustomer.get('fullName').invalid && this.formCustomer.get('fullName').touched;
    }

    get typeRefund() { return this.formOperation.get('typeRefund') }
    get transactionType() { return this.formOperation.get('transactionType') }

    createForm() {
        this.formRefund = this.fb.group({
            operation: this.fb.group({
                referenceType: ['', [Validators.required]],
                reference: ['', [Validators.required]],
                type: [null, [Validators.required]],
                amount: ['', [Validators.required]],
                typeRefund: [null, [Validators.required]],
                transactionType: [null],
            }),
            customer: this.fb.group({
                documentType: ['', [Validators.required]],
                documentNumber: ['', [Validators.required]],
                email: ['', [Validators.required, Validators.email]],
                phone: ['', [Validators.required]],
                bank: ['', [Validators.required]],
                agencyNumber: ['', [Validators.required]],
                accountType: ['', [Validators.required]],
                numberAccount: ['', [Validators.required]],
                keyType: [''],
                keyValue: [''],
                fullName: ['', [Validators.required]],
            }),
        });
    }

    getId() {
        this.route.paramMap.subscribe(({params}: any) => {
            this.idOperation = params.id;
            this.getOperation(params.id);
        });
    }

    getOperation(id) {
        const request = {
            token: JSON.parse(this.cookie.get('ud')).token,
            merchant: this.merchant,
            service: 'cashin/transactions/detail/',
            type: 'get',
            data: {
                PublicId: id
            }
        };

        this.api.api(request).toPromise().then((res: any) => {
            $('#charging').addClass('hide');
            const amount = res.Transaction.Amount;
            this.formOperation.get('amount').setValidators([Validators.required, Validators.max(Number(amount))]);
            this.formOperation.get('amount').updateValueAndValidity();
            this.operation = res;
            this.countryCodeTransaction = res.Transaction.CountryCode;
            this.isRefundCompleted = res.Transaction.IsRefundCompleted;
            this.refundTypes.forEach(type => {
                if (type.value === 1) {
                    type.label = res.Transaction.LegalNameProvider || 'Liquidación';
                }
            });            
            if (this.countryCodeTransaction === 'BRA') {
                this.transactionType.setValidators([Validators.required]);
                this.transactionType.updateValueAndValidity();
            }
            this.getCountrySupport();
            this.setForm(res);
        }).catch(e => {
            $('#charging').addClass('hide');
            if (e.status === 401 || e.status === 0) {
                console.error(e);
            }
        });
    }

    setForm(operation) {
        this.formOperation.get('referenceType').setValue(this.referenceTypes[0].value);
        this.formOperation.get('referenceType').disable();
        this.formOperation.get('reference').setValue(this.idOperation);
        this.formOperation.get('reference').disable();
        this.formCustomer.get('fullName').setValue(operation.Customer.FullName);
        this.formCustomer.get('documentNumber').setValue(operation.Customer.DocNumber);
        this.formCustomer.get('email').setValue(operation.Customer.Email);
        this.formCustomer.get('phone').setValue(operation.Customer.Mobile);
        this.formCustomer.get('documentType').setValue(operation.Customer.DocType);
    }

    nextTab(): void {
        if (this.formOperation.invalid) {
            this.formOperation.markAllAsTouched();
            return;
        }
        this.selectedIndexTab += 1;
    }

    backTab(): void {
        this.selectedIndexTab -= 1;
    }

    selectChange(value): void {
        this.selectedIndexTab = value.index;
    }

    submit() {
        this.setDataInfo();
    }

    clearValues(keys) {
        keys.forEach(key => {
            if (this.formOperation.get(key)) {
                this.formOperation.get(key).setValue(null);
            }
            if (this.formCustomer.get(key)) {
                this.formCustomer.get(key).setValue(null);
            }
        });
    }

    clearValidations(keys) {
        keys.forEach(key => {
            if (this.formOperation.get(key)) {
                this.formOperation.get(key).clearValidators();
                this.formOperation.get(key).updateValueAndValidity(); 
            }
            if (this.formCustomer.get(key)) {
                this.formCustomer.get(key).clearValidators();
                this.formCustomer.get(key).updateValueAndValidity(); 
            }
        });
    }

    setValidations(validations: {key: string, validations: ValidatorFn[]}[]) {
        validations.forEach(validation => {
            if (this.formOperation.get(validation.key)) {
                this.formOperation.get(validation.key).setValidators(validation.validations);
                this.formOperation.get(validation.key).updateValueAndValidity();
            }
            if (this.formCustomer.get(validation.key)) {
                this.formCustomer.get(validation.key).setValidators(validation.validations);
                this.formCustomer.get(validation.key).updateValueAndValidity();
            }
        });
    }

    setDataInfo() {
        if (this.formOperation.get('referenceType').value === 'PublicID') {
            this.dataRefund.PublicID = this.formOperation.get('reference').value;
            this.dataRefund.MerchantSalesID = this.operation.Transaction.MerchantSalesID || '';
        } else {
            this.dataRefund.MerchantSalesID = this.formOperation.get('reference').value;
        }
        this.dataRefund.Type = this.formOperation.get('type').value.toString();
        this.dataRefund.Amount = Number(this.formOperation.get('amount').value);
        this.dataRefund.MerchantCode = this.merchant.toString();
        this.dataRefund.TypeDeposit = this.formOperation.get('typeRefund').value;
        this.dataRefund.Details.DocumentType = this.formCustomer.get('documentType').value;
        this.dataRefund.Details.DocumentNumber = this.formCustomer.get('documentNumber').value;
        this.dataRefund.Details.Email = this.formCustomer.get('email').value;
        this.dataRefund.Details.Phone = this.formCustomer.get('phone').value;
        this.dataRefund.Details.NameBank = this.formCustomer.get('bank').value;
        this.dataRefund.Details.NumberAgence = this.formCustomer.get('agencyNumber').value;
        this.dataRefund.Details.TypeAccount = this.formCustomer.get('accountType').value;
        this.dataRefund.Details.NumberAccount = this.formCustomer.get('numberAccount').value;
        this.dataRefund.Details.FullName = this.formCustomer.get('fullName').value;
        this.dataRefund.TypeTransfer = this.formOperation.get('transactionType').value;
        this.dataRefund.MetaData = {
            KeyType: this.formCustomer.get('keyType').value,
            KeyValue: this.formCustomer.get('keyValue').value,
        };

        this.createRefund();
    }

    createRefund() {
        if (this.formRefund.valid) {
            const request = {
                token: JSON.parse(this.cookie.get('ud')).token,
                merchant: this.merchant,
                service: 'cashin/refund_order',
                type: 'post',
                data: this.dataRefund
            };

            this.api.api(request).toPromise().then((res: any) => {
                $('#charging').addClass('hide');
                this.showSuccessData = true;
                this.success('Devolución creada', 'La devolución fue creada correctamente');
            }).catch(e => {
                $('#charging').addClass('hide');
            });
        } else {
            this.formRefund.markAllAsTouched();
        }
    }

    success(title, message?: string): void {
        this.modal.success({nzTitle: title, nzContent: message});
    }

    error(errors: Array<string>): void {
        let messageError = '';
        errors.forEach(msg => messageError += msg + '<br>');
        console.log(messageError);
        
        this.modal.error({nzTitle: 'Error' , nzContent: messageError});
    }

    getCountrySupport() {

        switch (this.countryCodeTransaction) {
            case 'BRA':
                this.documentTypes = this.documentTypesBRA;
                break;
            case 'PER':
                this.documentTypes = this.documentTypesPER;
                break;
            case 'COL':
                this.documentTypes = this.documentTypesCOL;
                break;
            case 'MEX':
                this.documentTypes = this.documentTypesMEX;
                break;
            default:
                this.documentTypes = this.documentTypesPER;
        }
    }

    changeTypeRefund() {
        this.formOperation.get('typeRefund').valueChanges.pipe(
            takeUntil(this.destroy$)
        ).subscribe(res => {
            const keyWordsStepTwo = [
                'documentType',
                'bank',
                'agencyNumber',
                'accountType',
                'numberAccount',
                'phone'
            ]
            if (res === 1) {
                this.clearValues(keyWordsStepTwo);
                this.clearValidations(keyWordsStepTwo);
                this.setForm(this.operation);
                if (this.countryCodeTransaction === 'BRA') {
                    this.transactionType.disable();
                    this.transactionType.setValue(null);
                }
            }
            if (res === 2) {
                this.clearValues(keyWordsStepTwo);
                this.setValidations([
                    {key: 'documentType', validations: [Validators.required]},
                    {key: 'documentNumber', validations: [Validators.required]},
                    {key: 'email', validations: [Validators.required, Validators.email]},
                    {key: 'phone', validations: [Validators.required]},
                    {key: 'bank', validations: [Validators.required]},
                    {key: 'agencyNumber', validations: [Validators.required]},
                    {key: 'accountType', validations: [Validators.required]},
                    {key: 'numberAccount', validations: [Validators.required]},
                    {key: 'fullName', validations: [Validators.required]},

                ]);
                if (this.countryCodeTransaction === 'BRA') {
                    this.transactionType.enable();
                    this.setValidations([
                        {key: 'transactionType', validations: [Validators.required]},
                    ]);
                }
            }
        });
    }

    changeType() {
        this.formOperation.get('type').valueChanges.pipe(
            takeUntil(this.destroy$)
        ).subscribe(value => {
            this.refundTypes.forEach(type => {
                if (type.value === 1) {
                    type.disabled = value === 2
                }
            });
            if (value === 1) {
                this.formOperation.get('amount').setValue(this.operation.Transaction.Amount);
                this.formOperation.get('amount').disable();
            } else {
                this.formOperation.get('amount').setValue('');
                this.formOperation.get('amount').enable();
                this.formOperation.get('typeRefund').setValue(null);
            }
        });
    }

    changeKeyType() {
        this.formCustomer.get('keyType').valueChanges.pipe(
            takeUntil(this.destroy$)
        ).subscribe(value => {
            if (value === 'correo') {
                this.formCustomer.get('keyValue').setValidators([Validators.required, Validators.email]);
                this.formCustomer.get('keyValue').updateValueAndValidity();
            } else {
                this.formCustomer.get('keyValue').setValidators([Validators.required]);
                this.formCustomer.get('keyValue').updateValueAndValidity();
                this.formCustomer.get('keyValue').setValue('');
            }
        });
    }

    changeTransactionType(value: 'PIX' | 'TRANSFER_BANK') {
        if(value === 'PIX') {
            this.isPIX = true;
            this.clearValues(['bank', 'agencyNumber', 'accountType', 'numberAccount']);
            this.clearValidations(['bank', 'agencyNumber', 'accountType', 'numberAccount']);
            this.formCustomer.get('keyType').setValidators([Validators.required]);
            this.formCustomer.get('keyType').updateValueAndValidity();
            this.formCustomer.get('keyValue').setValidators([Validators.required]);
            this.formCustomer.get('keyValue').updateValueAndValidity();
        }
        if (value === 'TRANSFER_BANK') {
            this.isPIX = false;
            this.clearValues(['keyType', 'keyValue']);
            this.clearValidations(['keyType', 'keyValue']);
            this.formCustomer.get('bank').setValidators([Validators.required]);
            this.formCustomer.get('bank').updateValueAndValidity();
            this.formCustomer.get('agencyNumber').setValidators([Validators.required]);
            this.formCustomer.get('agencyNumber').updateValueAndValidity();
            this.formCustomer.get('accountType').setValidators([Validators.required]);
            this.formCustomer.get('accountType').updateValueAndValidity();
            this.formCustomer.get('numberAccount').setValidators([Validators.required]);
            this.formCustomer.get('numberAccount').updateValueAndValidity();
            
        }
        
    }

    goBack() {
        this.router.navigate(['cashin-payments-orders']);
    }
}
